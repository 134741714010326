import memoize from 'lodash/memoize';

import { challengeAPI } from '@wix/challenges-web-api/dist/src/API';
import {
  ListChallengesRequest,
  ListChallengesResponse,
  MemberRole,
  SortingCriterion,
  V1ChallengeState,
} from '@wix/ambassador-challenge-service-web/types';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isMA } from '../../selectors/isMA';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { getMockedChallenges } from '../main/getMockedChallenges';

export interface IChallengesListDataProps {
  challengesListData: ListChallengesResponse;
}

async function getChallengesList(
  memberId: string,
  options?: Partial<ListChallengesRequest>,
) {
  return challengeAPI.listChallenges({
    memberId,
    memberRoles: [],
    paging: {
      limit: 100,
    },
    states: [V1ChallengeState.PUBLISHED, V1ChallengeState.FINISHED],
    ...options,
  });
}

async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async (user) => {
    let challenges;

    try {
      challenges = await getChallengesList(user.id);
    } catch (error) {
      handleError({
        error,
        context: 'onLogin.getChallengeList',
      });
    }

    flowAPI.controllerConfig.setProps({
      challengesListData: {
        ...challenges,
      },
    });
  });
}

const STORAGE_FIELD = 'CHALLENGES_LIST';
const MA_STORAGE_FIELD = 'MA_CHALLENGES_LIST';

export const challengesListDataProviderPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IChallengesListDataProps> {
  const { isViewer } = flowAPI.environment;
  const userData = await userProviderPropsMap(flowAPI);

  const memberId = userData.user.id;
  const storageField = isMA(flowAPI) ? MA_STORAGE_FIELD : STORAGE_FIELD;

  let challenges;

  await handleUserLogin(flowAPI);

  if (
    flowAPI.controllerConfig.platformAPIs.storage.memory &&
    flowAPI.controllerConfig.platformAPIs.storage.memory.getItem
  ) {
    try {
      const tmpList = flowAPI.controllerConfig.platformAPIs.storage.memory.getItem(
        storageField,
      );
      challenges = tmpList ? JSON.parse(tmpList) : null;
    } catch (error) {
      handleError({
        error,
        context: 'storage.getChallengeList',
      });
    }
  }

  if (!challenges) {
    try {
      challenges = await getChallengesList(memberId, {
        memberRoles: isMA(flowAPI) && isViewer ? [MemberRole.PARTICIPANT] : [],
        sortingCriterion: isMA(flowAPI)
          ? SortingCriterion.ACTIVE_FIRST
          : SortingCriterion.DEFAULT,
      });

      if (
        !flowAPI.environment.isEditor &&
        flowAPI.controllerConfig.platformAPIs.storage.memory &&
        flowAPI.controllerConfig.platformAPIs.storage.memory.setItem
      ) {
        flowAPI.controllerConfig.platformAPIs.storage.memory.setItem(
          storageField,
          JSON.stringify(challenges),
        );
      }
    } catch (error) {
      handleError({ error, context: 'getChallengeList' });
    }
  }

  if (!isMA(flowAPI) && !isViewer && (!challenges || !challenges.totalCount)) {
    challenges = getMockedChallenges(flowAPI);
  }

  return {
    challengesListData: {
      ...challenges,
    },
  };
});
