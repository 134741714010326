import { IUserProviderProps } from './UserProvider';
import {
  getUserFromConfig,
  getUserType,
  handleUserLogin,
  promptLogin,
  UpdatedUserData,
  updateUserContext,
} from './helpers/userContextHelpers';
import userTypeHandlers from './helpers/userTypeHandlers';
import memoize from 'lodash/memoize';
import cloneDeep from 'lodash/cloneDeep';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { SelectedPaymentOption } from '../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import { getParticipant } from './helpers/getParticipant';
import { joinToChallenge } from './helpers/joinUserToChallenge';
import { cancelInvite } from './helpers/cancelInvite';
import { leaveTheChallenge } from './helpers/leaveTheChallenge';
import { getChallengeSlugFromLocation } from '../Location/locationProviderPropsMap';

export const userProviderPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IUserProviderProps> {
  const user = getUserFromConfig(flowAPI.controllerConfig);
  const slug = getChallengeSlugFromLocation(flowAPI);
  // participant will only appear on url with challengeId or slug;
  const participant = slug ? await getParticipant(flowAPI) : null;

  const userProviderProps: IUserProviderProps = {
    user,
    userType: getUserType(user, participant),
    participant,
    async promptLogin(): Promise<any> {
      return promptLogin(flowAPI);
    },
    async join(
      selectedPaymentOption: SelectedPaymentOption,
      startDate?: string,
    ): Promise<any> {
      return joinToChallenge(
        flowAPI,
        userProviderProps,
        selectedPaymentOption,
        startDate,
      );
    },
    userTypeHandlers,
    async cancelJoinRequest(challengeId?: string): Promise<void> {
      return cancelInvite(flowAPI, userProviderProps, challengeId);
    },
    async leaveTheChallenge(
      participantId: string,
      challengeId?: string,
    ): Promise<void> {
      return leaveTheChallenge(
        flowAPI,
        participantId,
        userProviderProps,
        challengeId,
      );
    },
    async incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData> {
      const participantCopy = cloneDeep(userProviderProps.participant);
      if (participantCopy?.stepsSummary) {
        participantCopy.stepsSummary.completedStepsNumber =
          participantCopy.stepsSummary.completedStepsNumber + 1;
      }
      return userProviderProps.updateParticipant(participantCopy);
    },
    async updateParticipant(newParticipant): Promise<UpdatedUserData> {
      const userData = await updateUserContext(flowAPI, newParticipant);

      Object.entries(userData).forEach(([key, val]) => {
        userProviderProps[key] = val;
      });

      flowAPI.controllerConfig.setProps(
        userData as Partial<IUserProviderProps>,
      );

      return userData;
    },
  };

  await handleUserLogin(flowAPI, userProviderProps);

  return userProviderProps;
});
