import { approvalAPI } from '@wix/challenges-web-api/dist/src/API';
import { ActionTypes, ScreenNames } from '../../BI/interfaces';
import { ApprovalRequestResolutionStatus } from '@wix/ambassador-challenge-service-web/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';

async function getApprovalRequestId(
  flowAPI: ControllerFlowAPI,
  challengeId?: string,
) {
  challengeId = challengeId ? challengeId : await getChallengeId(flowAPI);
  try {
    const approvals = await approvalAPI.listApprovalRequests({
      challengeId,
      resolverId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
      includeResolved: false,
    });

    return approvals?.requests?.['0']?.id;
  } catch (error) {
    handleError({ error, context: 'acceptInvite.listApprovalRequests' });
  }
}

export async function acceptInvite(
  flowAPI: ControllerFlowAPI,
  timeZone: string,
  startDate: string,
) {
  const approvalRequestId = await getApprovalRequestId(flowAPI);

  if (approvalRequestId) {
    const bi = biProviderPropsMap({
      biSettings: {
        screenName: ScreenNames.ChallengePage,
        getItemsCount: () => 1,
      },
      flowAPI,
    });
    await bi.beforeAndAfterAction(ActionTypes.CHALLENGE_JOIN, (actionId) =>
      approvalAPI.resolveApprovalRequest({
        actionId,
        memberTimeZone: timeZone,
        requestId: approvalRequestId,
        resolutionStatus: ApprovalRequestResolutionStatus.ACCEPTED,
        startDate,
      }),
    );
  } else {
    return;
  }
}
