import { getChallengeSlugFromLocation } from '../Location/locationProviderPropsMap';
import { isAvailableForJoinImmediately } from '../../selectors/challenges';
import {
  GetChallengeResponse,
  V1Challenge,
} from '@wix/ambassador-challenge-service-web/types';
import memoize from 'lodash/memoize';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { challengesListDataProviderPropsMap } from '../ChallengesListDataProvider/challengesListDataProviderPropsMap';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { syncInstance } from '../../services/instance';
import { biProviderPropsMap } from '../BI/BIProviderPropsMap';
import {
  handleUserLogin,
  IChallengeDataProps,
  requestChallenge,
  shouldReportSentry,
} from './helpers';
import { GROUPS_APP_ID } from '../../editor/app-config';

export const challengeDataProviderPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IChallengeDataProps> {
  const slug = getChallengeSlugFromLocation(flowAPI);
  const { isEditor, isPreview } = flowAPI.environment;
  const bi = biProviderPropsMap({ flowAPI });

  const challengeResponse: GetChallengeResponse = {
    challenge: null,
  };

  syncInstance(flowAPI);
  await handleUserLogin(flowAPI);

  try {
    if (!isEditor && slug) {
      challengeResponse.challenge = await requestChallenge(slug, flowAPI);
      bi.setChallengeId(challengeResponse?.challenge?.id);
    }
  } catch (error) {
    if (error.httpStatus === 403) {
      console.log(error);
      return;
    }

    handleError({
      error,
      context: 'getOwnerChallenge',
      preventSentryReport: !shouldReportSentry(error),
    });
  }

  if (isEditor || (!challengeResponse.challenge && isPreview)) {
    const { challengesListData } = await challengesListDataProviderPropsMap(
      flowAPI,
    );
    challengeResponse.challenge = challengesListData?.memberChallenges[0]
      ?.challenge as V1Challenge;
  }

  const isInstalled = await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled(
    {
      sectionId: 'group',
      appDefinitionId: GROUPS_APP_ID,
    },
  );

  return {
    isGroupsInstalled: isInstalled,
    challengeData: {
      ...challengeResponse,
      ...{
        isAvailableForJoinImmediately: isAvailableForJoinImmediately(
          challengeResponse.challenge,
        ),
      },
    },
  };
});

export async function getChallengeId(flowAPI: ControllerFlowAPI) {
  try {
    const provider = await challengeDataProviderPropsMap(flowAPI);
    return provider?.challengeData?.challenge?.id;
  } catch (error) {
    handleError({ error, context: 'get challenge id' });
  }
}
