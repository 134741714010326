import initSchemaLogger, { Logger } from '@wix/bi-logger-challenges-member-web';
import { VIEW_MODE } from '../../config/constants';
import { BIProviderPropsMap, IBiSettings } from './interfaces';
import uuid from 'uuid';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

let bi: BIProviderPropsMap = null;
let challengeId;
let screenName;

export const biProviderPropsMap = ({
  biSettings,
  flowAPI,
}: {
  biSettings?: IBiSettings;
  flowAPI: ControllerFlowAPI;
}): BIProviderPropsMap => {
  if (bi) {
    return bi;
  }
  screenName = biSettings.screenName;
  const { viewMode } = flowAPI.controllerConfig.wixCodeApi.window;

  const { platformAPIs } = flowAPI.controllerConfig;
  const { ownerId } = platformAPIs.bi;

  const biLogger: Logger = initSchemaLogger(platformAPIs.biLoggerFactory())({
    defaults: {
      origin: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
      _uuid: ownerId,
    },
  });
  const startTimeOfRender = Date.now();

  bi = {
    ownerId,
    startTimeOfRender,
    memberWebAppButtonClick(options) {
      return biLogger.memberWebAppButtonClick({
        screenName,
        challengeId: biSettings.preventAutomaticallySendChallengeId
          ? undefined
          : challengeId,
        ...options,
      });
    },
    setChallengeId(chId: string) {
      challengeId = chId;
    },
    setScreenName(scrName: string) {
      screenName = scrName;
    },
    beforeAndAfterAction: async (actionType, fn) => {
      const actionId = uuid();
      await biLogger.memberWebAppOnBeforeAction({
        actionId,
        actionType,
        screenName,
      });
      const response = await fn(actionId);
      await biLogger.memberWebOnAfterAction({
        actionId,
        actionType,
        screenName,
      });
      return response;
    },
    afterAction: async (actionType, startDate = null) => {
      const actionId = uuid();
      await biLogger.memberWebOnAfterAction({
        actionId,
        actionType,
        screenName,
        startDate_: startDate,
      });
    },
    screenOpen: async (scrName = screenName) => {
      screenName = scrName;
      await biLogger.memberWebAppScreenOpen({
        screenName,
        challengeId: biSettings.preventAutomaticallySendChallengeId
          ? undefined
          : challengeId,
      });
    },
    tabOpen: async (options) => {
      await biLogger.memberWebAppTabClick({
        screenName,
        challengeId: biSettings.preventAutomaticallySendChallengeId
          ? undefined
          : challengeId,
        ...options,
      });
    },
    reportSSRIsRendered: async () => {
      if (viewMode === VIEW_MODE.Site) {
        const now = new Date().getTime();
        await biLogger.memberWebWidgetShown({
          timeSpent: startTimeOfRender ? now - startTimeOfRender : 0,
          screenName,
          itemsCount: await biSettings.getItemsCount(flowAPI.controllerConfig),
          challengeId: biSettings.preventAutomaticallySendChallengeId
            ? undefined
            : challengeId,
        });
      }
    },
    quizCheckSnapshot: async (stepId: string, questionsJson: any) => {
      await biLogger.memberWebAppQuizCheckSnapshot({
        screenName,
        challengeId: biSettings.preventAutomaticallySendChallengeId
          ? undefined
          : challengeId,
        origin: 'QuizQuestions',
        step: stepId,
        questionsJson,
      });
    },
  };

  return bi;
};
