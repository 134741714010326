import {
  ChallengeSection,
  ParticipantSection,
} from '@wix/ambassador-challenge-service-web/types';
import { toParticipantSteps } from '../ParticipantStepsDataProvider/toParticipantSteps';

export const toParticipantSections = (
  ownerSections: ChallengeSection[],
): ParticipantSection[] => {
  return ownerSections.map((section) => {
    console.log(section);

    return {
      id: section?.id,
      source: section,
      steps: toParticipantSteps({ steps: section?.steps || ([] as any) }),
      transitions: [],
    };
  });
};
