import { IDefaultBIParams, IGeneralDataContext } from './GeneralDataContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IPlatformAPI } from '@wix/native-components-infra/dist/src/types/types';

export interface IGeneralDataProviderProps extends IGeneralDataContext {}

function getBIParams(props: { platformAPIs: IPlatformAPI }): IDefaultBIParams {
  const biDefaults =
    props.platformAPIs?.biLoggerFactory?.()?.loggerClientFactory?._defaults ||
    {};

  return {
    metaSiteId: props.platformAPIs.bi.metaSiteId,
    siteOwnerId: props.platformAPIs.bi.ownerId,
    visitorId: props.platformAPIs.bi.visitorId,
    appId: biDefaults?._appId,
    bsi: biDefaults?.bsi?.(),
    instanceId: biDefaults?._instanceId,
  };
}

export function generalDataProviderPropsMap(
  flowAPI: ControllerFlowAPI,
): IGeneralDataProviderProps {
  const { appDefinitionId } = flowAPI.environment;
  const defaultBIParams = getBIParams({
    platformAPIs: flowAPI.controllerConfig.platformAPIs,
  });
  const instance = flowAPI.controllerConfig.wixCodeApi.site.getAppToken(
    appDefinitionId,
  );

  return {
    instance,
    instanceId: defaultBIParams.instanceId,
    msid: defaultBIParams.metaSiteId,
    defaultBIParams,
  };
}
