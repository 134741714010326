import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { ActionTypes, ScreenNames } from '../../BI/interfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { IUserContext } from '../UserContext';
import { paidPlansPropsMap } from '../../PaidPlans/paidPlansPropsMap';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';

export async function leaveTheChallenge(
  flowAPI: ControllerFlowAPI,
  participantId: string,
  userProvider: IUserContext,
  challengeId?: string,
) {
  const bi = biProviderPropsMap({
    biSettings: {
      screenName: ScreenNames.ChallengePage,
      getItemsCount: () => 1,
    },
    flowAPI,
  });

  await bi.beforeAndAfterAction(
    ActionTypes.LEAVE_THE_CHALLENGE,
    async (actionId) =>
      participantAPI.deleteParticipant({
        actionId,
        challengeId: challengeId || (await getChallengeId(flowAPI)),
        participantId,
      }),
  );
  await userProvider.updateParticipant();

  // because of PPs doesn't load when member is joined we need to trigger load of PPs on leave challenge
  await paidPlansPropsMap(flowAPI);

  flowAPI.controllerConfig.setProps({
    participantSteps: [],
  });
}
