import { GetChallengeResponse } from '@wix/ambassador-challenge-service-web/types';
import { challengeAPI } from '@wix/challenges-web-api/dist/src/API';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeSlugFromLocation } from '../Location/locationProviderPropsMap';
import { syncInstance } from '../../services/instance';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isAvailableForJoinImmediately } from '../../selectors/challenges';
import { getMockedChallenge } from '../main/getMockedChallenges';

export interface IChallengeDataProps {
  isGroupsInstalled: boolean;
  challengeData: GetChallengeResponse & {
    isAvailableForJoinImmediately: boolean;
  };
}

export const shouldReportSentry = (err) => err?.httpStatus !== 403;

export async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async (user) => {
    let challengeResponse: GetChallengeResponse = {
      challenge: null,
    };
    const challengeId = getChallengeSlugFromLocation(flowAPI);

    syncInstance(flowAPI);

    try {
      if (challengeId) {
        challengeResponse = await challengeAPI.getChallenge({
          challengeId,
        });
      }
    } catch (error) {
      handleError({
        error,
        context: 'onUserLogin.getChallenge',
        preventSentryReport: !shouldReportSentry(error),
      });
    }

    flowAPI.controllerConfig.setProps({
      challengeData: {
        ...challengeResponse,
        ...{
          isAvailableForJoinImmediately: isAvailableForJoinImmediately(
            challengeResponse.challenge,
          ),
        },
      },
    });
  });
}

export async function requestChallenge(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
) {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (mockedChallenge) {
    return mockedChallenge;
  }

  if (!slugOrChallengeId) {
    return;
  }

  const findChallengeResponse = await challengeAPI.listChallenges({
    slug: slugOrChallengeId,
  });

  if (findChallengeResponse.totalCount === 1) {
    return findChallengeResponse?.memberChallenges?.[0]?.challenge;
  }
  // fallback to getting challenge by challengeId just in case
  return (
    await challengeAPI.getChallenge({
      challengeId: slugOrChallengeId,
    })
  ).challenge;
}
