import { ActionTypes, ScreenNames } from '../../BI/interfaces';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import {
  CreateJoinRequestResponse,
  V1Participant,
} from '@wix/ambassador-challenge-service-web/types';

export async function sendRequestForOwnersApprove(
  flowAPI: ControllerFlowAPI,
  timeZone: string,
  startDate,
): Promise<V1Participant> {
  const bi = biProviderPropsMap({
    biSettings: {
      screenName: ScreenNames.ChallengePage,
      getItemsCount: () => 1,
    },
    flowAPI,
  });
  const challengeId = await getChallengeId(flowAPI);
  const {
    participant,
  } = await bi.beforeAndAfterAction<CreateJoinRequestResponse>(
    ActionTypes.JOIN_REQUEST_CREATE,
    (actionId) =>
      participantAPI.createJoinRequest({
        challengeId,
        memberId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
        timeZone,
        startDate,
        actionId,
      }),
  );

  return participant;
}
