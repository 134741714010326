import { ILocation } from '@wix/native-components-infra/dist/src/types/types';
import { ILocationProviderProps } from './LocationProvider';
import {
  ILocationContext,
  LocalRouting,
  PageSlugs,
  ScreenNamesByPageId,
} from './LocationContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import memoize from 'lodash/memoize';
import {
  getDevOverwrites,
  getPageUrlInfo,
  getTargetUrl,
  withAllQueryParameters,
  withDevQueryParams,
} from './urlHelpers';
import { biProviderPropsMap } from '../BI/BIProviderPropsMap';

export interface ILocationProps extends ILocationContext {}

export const locationProviderPropsMap = memoize(
  (flowAPI: ControllerFlowAPI): ILocationProviderProps => {
    const location = (flowAPI.controllerConfig.wixCodeApi.location || {
      path: [],
      query: {},
      url: '',
    }) as ILocation;
    const query = location.query;
    const pageUrlInfo = getPageUrlInfo(location);
    const { isPreview } = flowAPI.environment;

    return {
      // @ts-expect-error
      location: {
        path: location.path,
        query: location.query,
        url: location.url,
        baseUrl: location.baseUrl,
      },
      currentLocation: withAllQueryParameters(pageUrlInfo.routerPath, flowAPI), // make initial location with all provided query parameters
      basePath: pageUrlInfo.basePath,
      path: [...location.path],
      query: {
        ...query,
      },
      goToPage: async (options) => {
        const { overrides } = getDevOverwrites(flowAPI);
        const useLocalRouting =
          !options.reloadPage && LocalRouting[options.pageId];
        if (isPreview && !useLocalRouting) {
          await flowAPI.controllerConfig.wixCodeApi.location.navigateTo({
            pageId: PageSlugs[options.pageId],
            state: options.challengeId,
            queryParams: { challengeId: options.challengeId, ...overrides },
          } as any);
        } else {
          if (useLocalRouting) {
            flowAPI.controllerConfig.setProps({
              currentLocation: withDevQueryParams(
                LocalRouting[options.pageId],
                flowAPI,
              ),
            } as Partial<ILocationProps>);
            biProviderPropsMap({ flowAPI }).screenOpen(
              ScreenNamesByPageId[options.pageId],
            );
            return LocalRouting[options.pageId];
          }

          flowAPI.controllerConfig.wixCodeApi.location.to(
            await getTargetUrl(options, flowAPI),
          );
        }
      },
      goToExternalUrl(url: string) {
        flowAPI.controllerConfig.wixCodeApi.location.to(url);
      },
    };
  },
);

export function _getChallengeSlugFromLocation(flowAPI: ControllerFlowAPI) {
  const location = locationProviderPropsMap(flowAPI);
  const { isPreview } = flowAPI.environment;

  if (isPreview) {
    return location.query.challengeId;
  }

  return location.path.find((segment, index, array) => {
    return array[index - 1] === PageSlugs.challenge_page;
  });
}

export function getChallengeSlugFromLocation(flowAPI: ControllerFlowAPI) {
  return _getChallengeSlugFromLocation(flowAPI);
}
