import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import {
  FeedbackItem,
  ResolutionStatus,
} from '@wix/ambassador-challenge-service-web/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IResolveStepContext } from './ResolveStepContext';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { biProviderPropsMap } from '../BI/BIProviderPropsMap';
import { ActionTypes, ScreenNames } from '../BI/interfaces';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { syncInstance } from '../../services/instance';
import { getChallengeId } from '../ChallengeDataProvider/challengeDataProviderPropsMap';

const resolveStep = async (
  flowAPI: ControllerFlowAPI,
  stepId: string,
  feedbackItems: FeedbackItem[] = [],
  isUpdate: boolean = false,
): Promise<void> => {
  const bi = biProviderPropsMap({
    biSettings: {
      screenName: ScreenNames.ChallengePageForParticipant,
      getItemsCount: () => 1,
    },
    flowAPI,
  });
  const { participant } = await userProviderPropsMap(flowAPI);

  syncInstance(flowAPI);

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: true,
  });

  const sendRequest = async (actionId) => {
    const request: any = {
      actionId,
      challengeId: await getChallengeId(flowAPI),
      feedback: {
        createdAt: new Date(),
        items: feedbackItems,
      },
      participantId: participant?.id,
      status: ResolutionStatus.COMPLETED,
      stepId,
    };

    if (isUpdate) {
      delete request.feedback.createdAt;
      delete request.status;

      request.feedback.updatedAt = new Date();
    }

    return !isUpdate
      ? participantAPI.resolveStep(request)
      : participantAPI.updateStepFeedback(request);
  };

  try {
    await bi.beforeAndAfterAction(
      !isUpdate ? ActionTypes.STEP_COMPLETE : ActionTypes.STEP_FEEDBACK_UPDATE,
      async (actionId) => {
        return feedbackItems?.length
          ? bi.beforeAndAfterAction(ActionTypes.FEEDBACK_SEND, () => {
              return sendRequest(actionId);
            })
          : sendRequest(actionId);
      },
    );
    if (!isUpdate) {
      const userProvider = await userProviderPropsMap(flowAPI);
      await userProvider.updateParticipant();
    }
  } catch (error) {
    handleError({
      error,
      context: 'resolveStep',
    });

    flowAPI.controllerConfig.setProps({
      resolveStepError: error.toString(),
    });
  }

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: false,
  });
};

export const resolveStepDataProviderPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<IResolveStepContext> {
  return {
    isResolveStepRequestInProgress: false,
    resolveStep: async (stepId, feedbackItems = [], isUpdate = false) =>
      resolveStep(flowAPI, stepId, feedbackItems, isUpdate),
    resolveStepError: null,
  };
};
