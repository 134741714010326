import {
  ControllerFlowAPI,
  ControllerParams,
  CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { ScreenNames } from '../../contexts/BI/interfaces';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { initApi } from '../../services/initApi';
import { ISettingsEvents, RolesState } from './Widget/settingsEvents';
import { ButtonState } from '../ChallengesList/Widget/settingsEvents';
import { Challenges } from '../../editor/types/Experiments';
import { isParticipantPage } from '../../selectors/participants';
import { renderSeoMetatags } from '../../services/SeoMetatags';

export const initChallengePage = async (flowAPI: ControllerFlowAPI) => {
  const { setProps } = flowAPI.controllerConfig;
  const { isEditor } = flowAPI.environment;
  try {
    const initialProps = {
      ...(await providersPropsMap({
        flowAPI,
        biSettings: {
          preventAutomaticallySendChallengeId: isEditor,
          screenName: ScreenNames.ChallengePage,
          getItemsCount: () => 1,
        },
        enabledProviders: [
          'challengeDataProvider',
          'inviteLinkProvider',
          'paidPlansProvider',
          'participantSectionsDataProvider',
          'participantStepsDataProvider',
          'resolveStepDataProvider',
        ],
      })),
    };
    if (flowAPI.experiments.enabled(Challenges.renderSeoTags)) {
      await renderSeoMetatags(flowAPI, initialProps?.challengeData?.challenge);
    }
    initialProps.setScreenName(
      isParticipantPage(initialProps as any)
        ? ScreenNames.ChallengePageForParticipant
        : ScreenNames.ChallengePage,
    );
    setProps({ ...initialProps });
  } catch (err) {
    console.error(err);
  }
};

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);

  initApi(flowAPI);

  settingsEventsHandler.on('buttonState', (value) => {
    setProps({
      buttonState: value,
    });
  });

  settingsEventsHandler.on('listLayoutSelectedTab', (value) => {
    setProps({
      shownTab: value,
    });
  });

  settingsEventsHandler.onReset(() => {
    setProps({
      buttonState: ButtonState.Default,
      pageRole: RolesState.Visitor,
    });
  });

  return {
    async pageReady() {
      await initChallengePage(flowAPI);
    },
    updateConfig($w, config) {
      // notify (events should be fired)
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
    },
  };
};

export default createController;
